import React from 'react'
import VenueTVPage from '../pagesComponents/venue-tv'
import Layout from '../layout'

const VenueTv = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const groupId = params.get('groupId')?.toString() || null
	const eventId = params.get('eventId')?.toString() || null
	const groupName = params.get('groupName')?.toString() || null

	return (
		<Layout title="Corporate TV">
			<VenueTVPage
				groupId={groupId}
				eventId={eventId}
				groupName={groupName}
			/>
		</Layout>
	)
}

export default VenueTv
